//React
import { init } from 'ityped'
import {useEffect, useRef} from 'react'

//Styling
import './welcome.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
export default function Welcome() {

  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed:60,
      strings: ["Developer", "Guitarist","Designer","Researcher","Dinosaur Enthusiast", "Coder"],
    });
  }, []);

  return (
    <div className='welcome' id='welcome'>
        <div className="left">
            <div className="image-container">
               <img src="../images/me9.png " alt="me" /> 
            </div>
        </div>
        <div className="right">
          <div className="wrapper">
            <h4>Hi, I'm </h4>
            <h1>Mark Daniel</h1>
            <h3 >
               <span ref={textRef}></span>
              </h3> 
           
          </div>
          <a href="#Portfolio"><KeyboardArrowDownIcon className="icon"/></a>
          
        </div>
    </div>
  )
}
