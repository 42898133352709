import './work.scss'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useState} from 'react'
import Carousel from './carousel';

export default function Work() {


  const [currentSlide,setCurrentSlide]= useState(0);

  const clickHandler = (direction) => {

    if (direction === 'right' && currentSlide< data.length-1){
      setCurrentSlide(currentSlide + 1)
    } else if (direction ==='left' && currentSlide>0) {
      setCurrentSlide(currentSlide - 1)
    }
  }

  const data = [
    {id: 'RBC',
  image: 'RBC.png',
  title: 'Software Engineer Intern',
  desc: 'As a Software Engineer Intern at RBC, I worked with experts in Java, Python, and SQL. My time at RBC not only expanded my technical knowledge but sparked an interest in the intersection of technology and finance.',
  link: 'https://www.rbcits.com/en/who-we-are/careers/careers-internships.page'
},
    {id: 'mmrl',
    image: 'mmrl.png',
    title: 'MMRL Research Assistant',
    desc: 'I was a research assistant at the McGill Mindfulness Research Labs since October, 2020. I spent most of my time automating quantitative and qualitative data entry, and outreach.',
    s_desc: 'I spent most of my time automating quantitative and qualitative data entry, and outreach.',
    link: 'https://www.mcgill.ca/mmrl/'
  },
  {id: 'opensource',
  image: 'oss.png',
  title: 'Open Source Contributions',
  desc: 'I have contributed to many open source projects, such as web3.js, monero, MUI and more! Although I mostly contribute to MUI most of these contributions have been bug solving, and some minor feature/content adding.',
  s_desc:'I have contributed to many open source projects, such as web3.js, monero, bulma css and more!',
  link:'https://github.com/solana-labs/solana/pull/26345'
},
    {id: 'meditation',
    image: 'meditation.png',
    title: 'Meditation Club Founder',
    desc: 'I founded the meditation club at McGill in 2021 and grew it to 110+ members. I now teach a weekly class to a group of ~8-10 members, where we practice meditation and discuss the theory behind it.',
    s_desc:'I founded the meditation club at McGill in 2021 and grew it to 110+ members.',
    link:'https://www.facebook.com/themeditationclubmcgill/'
  },
    {id: 'team',
    image: 'mcgill.png',
    title: 'TEAM Mentor for SciLearn',
    desc: 'Presented Neuroscience topics to cohorts of 10-40 students to help them learn more efficiently. Afterwards, student\'s were surveyed for a machine learning project, to see if one\'s "Growth Mindset" predicted one\'s GPA.',
    s_desc:'Presented Neuroscience topics to cohorts of 10-40 students to help them learn more efficiently.',
    link:'https://www.mcgill.ca/ose/initiatives/scilearn'
  }
  ]

  

  

  return (
    <div className='work' id='Experience'>
      <div className='title-holder'>
      <h2 className='title'>Experience</h2>
      <h3 className='title-2'> Relevant Jobs And Experience </h3>
      <span className='line-2'>er </span>
      </div>

      {/* <Carousel data={data}></Carousel> */}

      <div className="slider" style={{transform: `translateX(-${currentSlide *100}vw)`}}>
      {data.map((d) => (<div className="container">
          <div className="item">
            <div className="left">
              <div className="left-container">
                <div className="img-content">
                  <AssignmentTurnedInIcon className='icon'/>
                </div>
                <h2>{d.title}</h2>
                <p className="description">{d.desc}</p>

                <p className="small_description"> {d.s_desc} </p>
              </div>
            </div>
            <div className="right">
              <a href={d.link} target='_blank' >
                <img src={`../../images/${d.image}`} alt={d.id} />
                </a>
            </div>
          </div>
        </div>))}
      </div>
      
      <ArrowForwardIosIcon onClick={() => clickHandler('left')} className={`arrow left ${currentSlide===0 && 'disabled'}`}/>
      <ArrowForwardIosIcon onClick={() => clickHandler('right')} className={`arrow right ${currentSlide===data.length -1 && 'disabled'}`}  />
      
    </div>
  )
}
