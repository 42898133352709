import './sidebar.scss'

export default function Sidebar({menuState,setMenuState}) {
  const menuHandler = () => {
    setMenuState(!menuState)
  }
  return (
    <div className={`sidebar ${menuState && 'active'}`}>
      <ul className="side">
        <li>
          <a href="#welcome" className="link" onClick={menuHandler}>Home</a>
        </li>
        <li>
          <a href="#portfolio" className="link" onClick={menuHandler}>Portfolio</a>
        </li>
        <li>
          <a href="#work" className="link" onClick={menuHandler}>Work</a>
        </li>
        <li>
          <a href="#contact" className="link" onClick={menuHandler}>Contact</a>
        </li>
      </ul>
    </div>
  )
}
