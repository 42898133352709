import { useLayoutEffect } from "react";
import "./portfolio.scss";
import PortfolioCard from "./portfolioDisplay/portfolioCard";
import "animate.css/animate.min.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


export default function Portfolio() {
    useLayoutEffect(() => {
      setTimeout(AOS.init({
        duration:1000
      }),10)
      AOS.refresh();
    }, []);


  const portfolioList = [
    {
      id: "gp",
      title: "Galactic Pong",
      text: "Won the 2022 McGill Physics Hackathon by Ponging General Relativity! Also won the Astrophysics sub-prize!",
      link: "https://jocular-queijadas-f8f5c6.netlify.app/",
      gitLink: "https://devpost.com/software/galactic-pong",
    },
    {
      id: "eth",
      title: "LotterETH",
      text: "Worked with web3 elements to create an interactive smart contract web-app using Next.js. Deployed on Rinkeby test-net.",
      link: "https://lotter-eth.vercel.app/",
      gitLink: "https://github.com/Mark-777-0/LotterEth",
    },
    {
      id: "redditA",
      title: "AutoBot9000",
      text: "Using Python, Selenium and BS4 created a bot that finds the top voted previous posts, and then cross-posts to grow a subreddit",
      gitLink: 'https://github.com/Mark-777-0/AutoBot9000'
    },
    { id: "oss",
    title: "OSS Contributions",
     text: "Open source contributions to mainly the Material UI React Library. Most were bug solving, and minor content additions.",
   gitLink:'https://github.com/mui/material-ui/pull/33803' }
   ,

  ];
  const portfolioListTwo = [
    {
      id: "algomap",
      title: "Algo-Map",
      text: "Algorithm Visualizer React App, the base was made following a tutorial. I've added bootstrap css, and various other algorithms",
      link: "https://meek-dieffenbachia-ef50bc.netlify.app/",
      gitLink: "https://github.com/Mark-777-0/AlgoMap",
    },
    {
      id: "spotnote",
      title: "SpotNote",
      text: "Social Media for Musicians! A place for musicians to post small thoughts about songwriting, production and music theory.",
      gitLink:'https://github.com/Mark-777-0/SpotNote'

    },
    {
      id: "JavaScript",
      title: "JS Apps",
      text: "Miscellaneous Apps written in pure javascript. Including frameworks like Skeleton CSS, Bootstrap CSS, FontAwesome...",
      link: "https://github.com/Mark-777-0/js-final-grade-calculator",
      gitLink: "https://github.com/Mark-777-0/js-todolist",
    },
    {
      id: "cc",
      title: "Commodity Cobra",
      text: "Created a Python bot using binary black-scholes to make markets for the Oat, Corn and Soybean commodities contracts.",
      gitLink: 'https://github.com/Mark-777-0/CommodityCobra',
    }
  ];

    
  return (
    <div className="portfolio" id="Portfolio">
      
      <h2>
        <span className="bumper">Projects </span>
      </h2>
      
      <h3>  See What I've Been Working On! </h3>
      
      <span className="line"> </span>
      

      {/* <ul>
      {portfolioList.map((item) => (
      <PortfolioDisplay
       title={item.title}
        active={selected === item.id}
        setSelected={setSelected}
        id={item.id}/>
      ))}
      </ul> */}
      
      <div className="cards">
        {portfolioList.map((item) => (
          <div className="singleCard">
            <PortfolioCard
              id={item.id}
              text={item.text}
              title={item.title}
              link={item.link}
              gitLink={item.gitLink}
            />
          </div>
        ))}
      </div>
      
      
      <div className="cards-2">
        {portfolioListTwo.map((item) => (
          <PortfolioCard id={item.id} text={item.text} title={item.title} link={item.link}
          gitLink={item.gitLink}/>
        ))}
      </div>
      
    </div>
  );
}
