import './contact.scss'
import { InputLabel, Card,Input, CardContent, Grid, TextField, Button, Box,Modal, Typography} from '@material-ui/core'
import { useRef, useState, useLayoutEffect} from "react";

import emailjs from "@emailjs/browser";



export default function Contact() {

  const [open, setOpen] = useState(false);
  const [err, setErr] = useState(false);
  const [formData, setFormData] = useState({
    from_name: "",
    last_name: "",
    user_email: "",
    message: ""
  });

  // const handleChange = (e) => setFormData( ...formData, user_email: e.target.value )

  const handleErrClose = () => {
    setErr(false);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const refForm= useRef()
  const sendEmail = (e)=>{
    e.preventDefault();

    if (formData.user_email === "") {
      setErr(true)
      return;
    }
    emailjs.sendForm('service_yqzf2lp', 'template_aza1hdq', refForm.current, 'nOjYC3rsCc9iirpZ5')
    .then(function() {
      console.log('SUCCESS!');
      setOpen(true);
      setFormData({
        from_name: "",
        last_name: "",
        user_email: "",
        message: ""
      });

  })
    
  }
  return (
    
    <div className="contact" id="Contact">
      

      <div>
      <Modal open={open} onClose={handleClose}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '2rem', borderRadius: '4px' }}>
          <Typography variant="h6" gutterBottom>
            Your E-mail Was Succesfully Sent!
          </Typography>
          <Typography variant="body1" gutterBottom>
          Our team of email enthusiasts is now on high alert, ready to decode your message with precision and grace.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal>
      <Modal open={err} onClose={handleErrClose}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '2rem', borderRadius: '4px' }}>
          <Typography variant="h6" gutterBottom>
          Error: Please Enter an Email Address.
          </Typography>
          
          <Typography variant="body1" gutterBottom>
          Your Email Entry is Empty!
          </Typography>
          <br></br>
          <Button   style={{ backgroundColor: 'red', color: 'white' }} variant="contained"  onClick={handleErrClose}>
            Close
          </Button>
        </div>
      </Modal>
    </div>


      <div className="left">
        <img src="../../images/logo.svg" alt="" />
     
      </div>
      <div className="right">

        <h2>Contact</h2>
        <div className="card">
          <Card sx={{overflow:'hidden',
            padding: 10}}>
            <CardContent style={{backgroundColor: '#fff', overflow:'hidden'}}>
              <form ref={refForm} onSubmit={sendEmail}>
              <Grid container spacing={1}>
                <Grid xs={12} sm={6} item>
                  <TextField
                    label="First Name"
                    name="from_name"
                    id='from_name'
                    type='text'
                    placeholder="Enter First Name"
                    value={formData.from_name}  onChange={(e) => setFormData({ ...formData, from_name: e.target.value })}
                    fullWidth
                  > <input type="text" name="from_name"> </input></TextField>
                </Grid>
                <Grid xs={12} sm={6} item>
                    <TextField
                      label="Last Name"
                      id='last_name'
                      name='last_name'
                      placeholder="Enter Last Name"
                      fullWidth
                      value={formData.last_name}  onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                      
                      aria-describedby="component-helper-text"
                    />

                </Grid>
                <Grid xs={12} item>
                  <TextField
                    type="email"
                    label="Email"
                    name="user_email"
                    id='user_email'
                    placeholder="Enter email"
                    fullWidth
                    value={formData.user_email}  onChange={(e) => setFormData({ ...formData, user_email: e.target.value })}
                  > <input type="email" name="user_email"  > </input></TextField>
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    type="message"
                    multiline
                    rows={4}
                    label="Message"
                    name='message'
                    placeholder="Send a message"
                    fullWidth
                    value={formData.message}  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Box className='boxBtn' textAlign='center'>
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    
                  >
                    Submit
                  </Button>
                  </Box>
                </Grid>
              </Grid>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
    
  );
}
